import React from "react";
import ViewSlider from '../../app/components/slider/ViewSlider';
import ConnectViewGallery from '../../app/components/gallery/ConnectViewGallery';

const GalleryView = ({ data }) => {
    const slideList = data.map(d => {
        return {
            type: 'raw',
            slide: <ConnectViewGallery data={ d }/>,
        }
    });
    

    const settings = {

	};
    return (
        <ViewSlider className="slider--transform-container" data={ { slideList: slideList, sliderOptions: settings } } />
    );
}

export default GalleryView;