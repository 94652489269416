const MENU_0 = 'Menu 0';
const MENU_18 = 'Menu 18';
const MENU_36 = 'Menu 36';
const MENU_54 = 'Menu 54';

export const data = {
    rotationHotspots: {
        0: [
            { title: MENU_0, left: '1300px', top: '185px' },
        ],
        14: [
            { title: MENU_18, left: '1300px', top: '185px' },
        ],
        30: [
            { title: MENU_36, left: '1300px', top: '185px' },
        ],
        54: [
            { title: MENU_54, left: '1300px', top: '185px' },
        ]
    },
    rotationPhotoHotspots: {
        0: [
            //{ title: 'queens wharf tower', left: '1062px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
        ],
        14: [
            //{ title: 'queens wharf tower', left: '1190px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
        ],
        30: [
            //{ title: 'queens wharf tower', left: '810px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
        ],
        54: [
           // { title: 'queens wharf tower', left: '750px', top: '420px', src: './img/masterplan-features/queens-wharf-tower-tmb.jpg', href: './img/gallery/QWT/v02.jpg' },
        ]
    },
    levelGroups: {
        'Menu 0': [
            { level: 'Rooftop', target: 'Rooftop' },
            { level: 'Level 5-7', target: 'Level 5' },
            { level: 'Level 3-4', target: 'Level 3' },
            { level: 'Level 1-2', target: 'Level 1' },
            { level: 'Ground', target: 'Ground', link: { label: 'Car Park Video', url: 'https://www.youtube.com/embed?v=SQrOHlheHHM' } },

        ],   
        'Menu 18': [
            { level: 'Rooftop', target: 'Rooftop' },
            { level: 'Level 5-7', target: 'Level 5' },
            { level: 'Level 3-4', target: 'Level 3' },
            { level: 'Level 1-2', target: 'Level 1' },
            { level: 'Ground', target: 'Ground', link: { label: 'Car Park Video', url: 'https://www.youtube.com/embed?v=SQrOHlheHHM' } },
        ],   
        'Menu 36': [
            { level: 'Rooftop', target: 'Rooftop' },
            { level: 'Level 5-7', target: 'Level 5' },
            { level: 'Level 3-4', target: 'Level 3' },
            { level: 'Level 1-2', target: 'Level 1' },
            { level: 'Ground', target: 'Ground', link: { label: 'Car Park Video', url: 'https://www.youtube.com/embed?v=SQrOHlheHHM' } },
        ],   
        'Menu 54': [
            { level: 'Rooftop', target: 'Rooftop' },
            { level: 'Level 5-7', target: 'Level 5' },
            { level: 'Level 3-4', target: 'Level 3' },
            { level: 'Level 1-2', target: 'Level 1' },
            { level: 'Ground', target: 'Ground', link: { label: 'Car Park Video', url: 'https://www.youtube.com/embed?v=SQrOHlheHHM' } },
        ],       
    },
    rotationOverlays: {
        0: {
            'Level 5-7': { src: './img/residences/3D_Floorplans_Highlights/frame_00_highlight_L5_L6_L7.png', top: 0, left: 0 },
            'Level 3-4': { src: './img/residences/3D_Floorplans_Highlights/frame_00_highlight_L3_L4.png', top: 0, left: 0 },
            'Level 1-2': { src: './img/residences/3D_Floorplans_Highlights/frame_00_highlight_L1_L2.png', top: 0, left: 0 },
        },
        14: {
            'Level 5-7': { src: './img/residences/3D_Floorplans_Highlights/frame_14_highlight_L5_L6_L7.png', top: 0, left: 0 },
            'Level 3-4': { src: './img/residences/3D_Floorplans_Highlights/frame_14_highlight_L3_L4.png', top: 0, left: 0 },
            'Level 1-2': { src: './img/residences/3D_Floorplans_Highlights/frame_14_highlight_L1_L2.png', top: 0, left: 0 },
        },
        30: {
            'Level 5-7': { src: './img/residences/3D_Floorplans_Highlights/frame_30_highlight_L5_L6_L7.png', top: 0, left: 0 },
            'Level 3-4': { src: './img/residences/3D_Floorplans_Highlights/frame_30_highlight_L3_L4.png', top: 0, left: 0 },
            'Level 1-2': { src: './img/residences/3D_Floorplans_Highlights/frame_30_highlight_L1_L2.png', top: 0, left: 0 },
        },
        54: {
            'Level 5-7': { src: './img/residences/3D_Floorplans_Highlights/frame_54_highlight_L5_L6_L7.png', top: 0, left: 0 },
            'Level 3-4': { src: './img/residences/3D_Floorplans_Highlights/frame_54_highlight_L3_L4.png', top: 0, left: 0 },
            'Level 1-2': { src: './img/residences/3D_Floorplans_Highlights/frame_54_highlight_L1_L2.png', top: 0, left: 0 },
        },
    },
    levelGroupApartments: {
        'Ground': [
            { left: '1040px', top: '280px', type: 'photo', src: './img/residences/carpark-photo.jpg', href: './img/residences/carpark.jpg' },
        ],
        'Level 1': [
            { title: '4C', left: '1182px', top: '362px', label: '1' },// label: '1' is for map reference only
            { title: '6C', left: '1120px', top: '514px', label: '2' },
            { title: '5C', left: '959px', top: '625px', label: '3' },
            { title: '7C', left: '849px', top: '765px', label: '4' },
            { title: '8C', left: '715px', top: '798px', label: '5' },
            { title: '9C', left: '715px', top: '686px', label: '6' },
            { title: '9C', left: '715px', top: '574px', label: '7' },
            { title: '1C', left: '570px', top: '341px', label: '8' },
            { title: '2A.C', left: '683px', top: '341px', label: '9' },
            { title: '2B.C', left: '792px', top: '341px', label: '10' },
            { title: '2B.C', left: '894px', top: '341px', label: '11' },
            { title: '3C', left: '1032px', top: '341px', label: '12' },
        ],
        'Level 2': [
            { title: '4C', left: '1182px', top: '362px', label: '1' },// label: '1' is for map reference only
            { title: '6C', left: '1120px', top: '514px', label: '2' },
            { title: '5C', left: '959px', top: '625px', label: '3' },
            { title: '7C', left: '849px', top: '765px', label: '4' },
            { title: '8C', left: '715px', top: '798px', label: '5' },
            { title: '9C', left: '715px', top: '686px', label: '6' },
            { title: '9C', left: '715px', top: '574px', label: '7' },
            { title: '1C', left: '570px', top: '341px', label: '8' },
            { title: '2A.C', left: '683px', top: '341px', label: '9' },
            { title: '2B.C', left: '792px', top: '341px', label: '10' },
            { title: '2B.C', left: '894px', top: '341px', label: '11' },
            { title: '3C', left: '1032px', top: '341px', label: '12' },
        ],
        'Level 3': [
            { title: '4', left: '1182px', top: '362px', label: '13' },// label: '1' is for map reference only
            { title: '6', left: '1120px', top: '514px', label: '14' },
            { title: '5', left: '959px', top: '625px', label: '15' },
            { title: '7', left: '849px', top: '765px', label: '16' },
            { title: '8', left: '715px', top: '798px', label: '17' },
            { title: '9', left: '715px', top: '686px', label: '18' },
            { title: '9', left: '715px', top: '574px', label: '19' },
            { title: '1', left: '570px', top: '341px', label: '20' },
            { title: '2A', left: '683px', top: '341px', label: '21' },
            { title: '2B', left: '792px', top: '341px', label: '22' },
            { title: '2B', left: '894px', top: '341px', label: '23' },
            { title: '3', left: '1032px', top: '341px', label: '24' },
        ],
        'Level 4': [
            { title: '4', left: '1182px', top: '362px', label: '13' },// label: '1' is for map reference only
            { title: '6', left: '1120px', top: '514px', label: '14' },
            { title: '5', left: '959px', top: '625px', label: '15' },
            { title: '7', left: '849px', top: '765px', label: '16' },
            { title: '8', left: '715px', top: '798px', label: '17' },
            { title: '9', left: '715px', top: '686px', label: '18' },
            { title: '9', left: '715px', top: '574px', label: '19' },
            { title: '1', left: '570px', top: '341px', label: '20' },
            { title: '2A', left: '683px', top: '341px', label: '21' },
            { title: '2B', left: '792px', top: '341px', label: '22' },
            { title: '2B', left: '894px', top: '341px', label: '23' },
            { title: '3', left: '1032px', top: '341px', label: '24' },
        ],
        'Level 5': [
            { title: '4', left: '1182px', top: '362px', label: '25' },// label: '1' is for map reference only
            { title: '6', left: '1120px', top: '514px', label: '26' },
            { title: '5R', left: '959px', top: '625px', label: '27' },
            { title: '7R', left: '849px', top: '765px', label: '28' },
            { title: '8R', left: '715px', top: '798px', label: '29' },
            { title: '9R', left: '715px', top: '686px', label: '30' },
            { title: '9R', left: '715px', top: '574px', label: '31' },
            { title: '1R', left: '570px', top: '341px', label: '32' },
            { title: '2A.R', left: '683px', top: '341px', label: '33' },
            { title: '2B.R', left: '792px', top: '341px', label: '34' },
            { title: '2B.R', left: '894px', top: '341px', label: '35' },
            { title: '3R', left: '1032px', top: '341px', label: '36' },
        ],
        'Level 6': [
            { title: '4', left: '1182px', top: '362px', label: '25' },// label: '1' is for map reference only
            { title: '6', left: '1120px', top: '514px', label: '26' },
            { title: '5R', left: '959px', top: '625px', label: '27' },
            { title: '7R', left: '849px', top: '765px', label: '28' },
            { title: '8R', left: '715px', top: '798px', label: '29' },
            { title: '9R', left: '715px', top: '686px', label: '30' },
            { title: '9R', left: '715px', top: '574px', label: '31' },
            { title: '1R', left: '570px', top: '341px', label: '32' },
            { title: '2A.R', left: '683px', top: '341px', label: '33' },
            { title: '2B.R', left: '792px', top: '341px', label: '34' },
            { title: '2B.R', left: '894px', top: '341px', label: '35' },
            { title: '3R', left: '1032px', top: '341px', label: '36' },
        ],
        'Rooftop': [
            //{ title: '4', left: '1182px', top: '362px', label: '25' },// label: '1' is for map reference only
            //{ title: '6', left: '1120px', top: '514px', label: '26' },
            { title: '5R', left: '959px', top: '625px', label: '27' },
            { title: '7R', left: '849px', top: '765px', label: '28' },
            { title: '8R', left: '715px', top: '798px', label: '29' },
            { title: '9R', left: '715px', top: '686px', label: '30' },
            { title: '9R', left: '715px', top: '574px', label: '31' },
            { title: '1R', left: '570px', top: '341px', label: '32' },
            { title: '2A.R', left: '683px', top: '341px', label: '33' },
            { title: '2B.R', left: '792px', top: '341px', label: '34' },
            { title: '2B.R', left: '894px', top: '341px', label: '35' },
            { title: '3R', left: '1032px', top: '341px', label: '36' },
            { left: '1240px', top: '415px', type: 'photo', src: './img/residences/rooftop-amenities-rooftop-pool.jpg', href: './img/residences/rooftop-amenities-rooftop-pool-large.jpg' },
            { left: '1180px', top: '505px', type: 'photo', src: './img/residences/rooftop-amenities-communal-living.jpg', href: './img/residences/rooftop-amenities-communal-living-large.jpg' },
        ],
    },

}
