
import ViewHome from './home/ViewHome';

import ViewMap from './map/ViewMap';
import ViewResidences from './residences/ViewResidences';
import { design, interiors, lifestyle } from './gallery/data/gallery';
import ViewVirtualTour from './tour/ViewVirtualTour';
import ViewDesign from './design/ViewDesign';
import ViewInteriors from './interiors/ViewInteriors';
import ViewLifestyle from './lifestyle/ViewLifestyle';
import ViewTeams from './teams/ViewTeams';
import ViewTour_Display_Suite from './interiors_display_suite/ViewTour_Display_Suite';

export const dataRoutes = [
    {
        title: 'Location',
        routes: [
            {
                title: 'Lifestyle',
                path: '/lifestyle',
                component: ViewLifestyle,
                data: [lifestyle],
            },
            {
                title: 'Map',
                path: '/location-map',
                component: ViewMap
            }
        ]
    },
    
    {
        title: 'Design',
        path: '/design',
        component: ViewDesign,
        data: [design],
    },
    {
        title: 'Interiors',
        routes: [
            {
                title: 'Renders',
                path: '/interiors',
                component: ViewInteriors,
                data: [interiors],
            },
            {
                title: 'Display Suite',
                path: '/interiors_display_suite',
                component: ViewTour_Display_Suite,
            }
        ]
    },
    {
        title: 'Residences',
        path: '/residences',
        component: ViewResidences ,
 
    },

    {
        title: 'Views',
        path: '/views',
        component: ViewVirtualTour
    },
    {

        title: 'Team',
        path: '/team',
        component: ViewTeams,

    },
    {
        title: 'Home',
        path: '/',
        component: ViewHome
    }
];