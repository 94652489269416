export const youtube = [{title: 'CAR PARK VIDEO', type: 'video', left: '1474px', top: '720px', src: 'https://www.youtube.com/watch?v=SQrOHlheHHM', href: 'https://www.youtube.com/watch?v=SQrOHlheHHM'}];

export const lifestyle = [
	{ title: '1', left: '634px', top: '111px', src: '/img/lifestyle/location-lifestyle-1-tmb.jpg', href: '/img/lifestyle/location-lifestyle-1.jpg' },
	{ title: '2', left: '1021px', top: '112px', src: '/img/lifestyle/location-lifestyle-2-tmb.jpg', href: '/img/lifestyle/location-lifestyle-2.jpg' },
	{ title: '3', left: '1452px', top: '112px', src: '/img/lifestyle/location-lifestyle-3-tmb.jpg', href: '/img/lifestyle/location-lifestyle-3.jpg' },
	{ title: '4', left: '634px', top: '446px', src: '/img/lifestyle/location-lifestyle-4-tmb.jpg', href: '/img/lifestyle/location-lifestyle-4.jpg' },
	{ title: '5', left: '1452px', top: '446px', src: '/img/lifestyle/location-lifestyle-5-tmb.jpg', href: '/img/lifestyle/location-lifestyle-5.jpg'}
]

export const interiors = [
	{ title: '1', left: '634px', top: '111px', src: '/img/interiors/design-interior-1-tmb.jpg', href: '/img/interiors/design-interior-1.jpg' },
	{ title: '4', left: '634px', top: '446px', src: '/img/interiors/design-interior-4-tmb.jpg', href: '/img/interiors/design-interior-4.jpg' },
	{ title: '5', left: '1452px', top: '446px', src: '/img/interiors/design-interior-5-tmb.jpg', href: '/img/interiors/design-interior-5.jpg'},
	{ title: '10', left: '2000px', top: '400px', src: '/img/interiors/design-interior-10-tmb.jpg', href: '/img/interiors/design-interior-10.jpg'},
	{ title: '3', left: '1452px', top: '111px', src: '/img/interiors/design-interior-3-tmb.jpg', href: '/img/interiors/design-interior-3.jpg' },
	{ title: '6', left: '2000px', top: '0px', src: '/img/interiors/design-interior-6-tmb.jpg', href: '/img/interiors/design-interior-6.jpg'},
	{ title: '11', left: '2000px', top: '500px', src: '/img/interiors/design-interior-11-tmb.jpg', href: '/img/interiors/design-interior-11.jpg'},
	{ title: '2', left: '1021px', top: '111px', src: '/img/interiors/design-interior-2-tmb.jpg', href: '/img/interiors/design-interior-2.jpg' },
	{ title: '3', left: '1452px', top: '111px', src: '/img/interiors/design-interior-2b-tmb.jpg', href: '/img/interiors/design-interior-2b.jpg' },
	{ title: '9', left: '2000px', top: '300px', src: '/img/interiors/design-interior-9-tmb.jpg', href: '/img/interiors/design-interior-9.jpg'},
	{ title: '7', left: '2000px', top: '100px', src: '/img/interiors/design-interior-7-tmb.jpg', href: '/img/interiors/design-interior-7.jpg'},
	{ title: '8', left: '2000px', top: '200px', src: '/img/interiors/design-interior-8-tmb.jpg', href: '/img/interiors/design-interior-8.jpg'}
]

export const design = [
	{ title: '1', left: '634px', top: '111px', src: '/img/design/design-exterior-1-tmb.jpg', href: '/img/design/design-exterior-1.jpg' },
	{ title: '2', left: '1183px', top: '111px', src: '/img/design/design-exterior-2-tmb.jpg', href: '/img/design/design-exterior-2.jpg' },
	{ title: '3', left: '1448px', top: '111px', src: '/img/design/design-exterior-3-tmb.jpg', href: '/img/design/design-exterior-3.jpg' },
	{ title: '4', left: '634px', top: '428px', src: '/img/design/design-exterior-4-tmb.jpg', href: '/img/design/design-exterior-4.jpg' },
	{ title: '5', left: '1183px', top: '465px', src: '/img/design/design-exterior-5-tmb.jpg', href: '/img/design/design-exterior-5.jpg'},
]

export const apartments1 = [
    { title: '1', left: '65px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page1_image_1_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page1_image_2_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page1_image_3_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page1_image_4_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page1_image_5_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page1_image_6_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page1_image_7_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page1_image_8_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page1_image_9_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page1_image_10_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page1_image_11_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page1_image_12_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_12_enlarge.jpg' },

];

export const apartments2 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page2_image_1_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page2_image_2_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page2_image_3_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page2_image_4_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page2_image_5_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page2_image_6_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page2_image_7_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page2_image_8_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page2_image_9_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page2_image_10_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page2_image_11_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page2_image_12_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_12_enlarge.jpg' },

];

export const apartments3 = [
    { title: '1', left: '65px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page1_image_1_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page1_image_2_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page1_image_3_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page1_image_4_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page1_image_5_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page1_image_6_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page1_image_7_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page1_image_8_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page1_image_9_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page1_image_10_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page1_image_11_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page1_image_12_thumb.jpg', href: '/img/gallery/apartments/gallery_apartments_page1_image_12_enlarge.jpg' },

];

export const apartments4 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page2_image_1_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page2_image_2_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page2_image_3_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/apartments/gallery_apartments_page2_image_4_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page2_image_5_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page2_image_6_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page2_image_7_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/apartments/gallery_apartments_page2_image_8_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page2_image_9_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page2_image_10_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page2_image_11_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/apartments/gallery_apartments_page2_image_12_thumb.jpg', href: '/img/gallery/gallery_apartments_page2_image_12_enlarge.jpg' },

];

export const amenities1 = [
    { title: '1', left: '65px', top: '15px', src: '/img/gallery/amenities/gallery_amenities_page1_image_1_thumb.jpg', href: '/img/gallery/amenities/gallery_amenities_page1_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/amenities/gallery_amenities_page1_image_2_thumb.jpg', href: '/img/gallery/amenities/gallery_amenities_page1_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/amenities/gallery_amenities_page1_image_3_thumb.jpg', href: '/img/gallery/amenities/gallery_amenities_page1_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/amenities/gallery_amenities_page1_image_4_thumb.jpg', href: '/img/gallery/amenities/gallery_amenities_page1_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/amenities/gallery_amenities_page1_image_5_thumb.jpg', href: '/img/gallery/amenities/gallery_amenities_page1_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/amenities/gallery_amenities_page1_image_6_thumb.jpg', href: '/img/gallery/amenities/gallery_amenities_page1_image_6_enlarge.jpg' },

];

export const amenities2 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/amenities/gallery_amenities_page2_image_1_thumb.jpg', href: '/img/gallery/gallery_amenities_page2_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/amenities/gallery_amenities_page2_image_2_thumb.jpg', href: '/img/gallery/gallery_amenities_page2_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/amenities/gallery_amenities_page2_image_3_thumb.jpg', href: '/img/gallery/gallery_amenities_page2_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/amenities/gallery_amenities_page2_image_4_thumb.jpg', href: '/img/gallery/gallery_amenities_page2_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/amenities/gallery_amenities_page2_image_5_thumb.jpg', href: '/img/gallery/gallery_amenities_page2_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/amenities/gallery_amenities_page2_image_6_thumb.jpg', href: '/img/gallery/gallery_amenities_page2_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/amenities/gallery_amenities_page2_image_7_thumb.jpg', href: '/img/gallery/gallery_amenities_page2_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/amenities/gallery_amenities_page2_image_8_thumb.jpg', href: '/img/gallery/gallery_amenities_page2_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/amenities/gallery_amenities_page2_image_9_thumb.jpg', href: '/img/gallery/gallery_amenities_page2_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/amenities/gallery_amenities_page2_image_10_thumb.jpg', href: '/img/gallery/gallery_amenities_page2_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/amenities/gallery_amenities_page2_image_11_thumb.jpg', href: '/img/gallery/gallery_amenities_page2_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/amenities/gallery_amenities_page2_image_12_thumb.jpg', href: '/img/gallery/gallery_amenities_page2_image_12_enlarge.jpg' },
];

export const precincts1 = [
    { title: '1', left: '65px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page1_image_1_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page1_image_2_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page1_image_3_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page1_image_4_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page1_image_5_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page1_image_6_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page1_image_7_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page1_image_8_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page1_image_9_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page1_image_10_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page1_image_11_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page1_image_12_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page1_image_12_enlarge.jpg' },

];

export const precincts2 = [
	{ title: '1', left: '65px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page2_image_1_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_1_enlarge.jpg' },
	{ title: '2', left: '515px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page2_image_2_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_2_enlarge.jpg' },
	{ title: '3', left: '965px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page2_image_3_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_3_enlarge.jpg' },
	{ title: '4', left: '1415px', top: '15px', src: '/img/gallery/precincts/gallery_precincts_page2_image_4_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_4_enlarge.jpg' },
	{ title: '5', left: '65px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page2_image_5_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_5_enlarge.jpg' },
	{ title: '6', left: '515px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page2_image_6_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_6_enlarge.jpg' },
	{ title: '7', left: '965px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page2_image_7_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_7_enlarge.jpg' },
	{ title: '8', left: '1415px', top: '340px', src: '/img/gallery/precincts/gallery_precincts_page2_image_8_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_8_enlarge.jpg' },
	{ title: '9', left: '65px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page2_image_9_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_9_enlarge.jpg' },
	{ title: '10', left: '515px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page2_image_10_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_10_enlarge.jpg' },
	{ title: '11', left: '965px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page2_image_11_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_11_enlarge.jpg' },
	{ title: '12', left: '1415px', top: '665px', src: '/img/gallery/precincts/gallery_precincts_page2_image_12_thumb.jpg', href: '/img/gallery/precincts/gallery_precincts_page2_image_12_enlarge.jpg' },
];