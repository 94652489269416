export const menuItemData = [
  {
    menuItemLabel: 'Browning St',
    type: 'standalone',
    dusk: process.env.PUBLIC_URL + '/vr-app/browning-dusk/index.htm',
    sunset: process.env.PUBLIC_URL + '/vr-app/browning-sunset/index.htm',
  },
  {
    menuItemLabel: 'Bessant St',
    type: 'standalone',
    dusk: process.env.PUBLIC_URL + '/vr-app/bessant-dusk/index.htm',
    sunset: process.env.PUBLIC_URL + '/vr-app/bessant-sunset/index.htm',
  },
    {
      menuItemLabel: 'Vulture St',
      type: 'standalone',
      sunset: process.env.PUBLIC_URL + '/vr-app/vulture-sunset/index.htm',
    },



];