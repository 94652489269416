import React, { Component } from 'react';



class View3dAPP extends Component {


      
      render() {  
        return(
            <div>
                <iframe src="https://my.matterport.com/show/?m=gDFddzjkVaz" frameBorder={0} style={{overflow: 'hidden', height: '1084px', width: '100%'}} height="1084px" width="100%" title="3DView" />
             </div>
        );
      }  
  }




export default View3dAPP;
//Photo by Sharon McCutcheon on Unsplash
//https://unsplash.com/photos/-vgLTTWPesg
