
import React, { useRef, useEffect } from 'react';
import LoadContainer from '../../shared/LoadContainer';


export default function ViewTeams () {


    return (
        <LoadContainer>
            <img className='shared--img-fill' src={process.env.PUBLIC_URL + '/img/teams/bkg--team-1.jpg'} width='1920' height='1080' alt='' style={{ marginTop: 100}} />
        </LoadContainer>    
    );

}