
export const data = [
    { 
        title: 'BARS & RESTAURANTS',
        options: [
            { title: 'Mama Taco' },
            { title: 'Lefkas Taverna' },
            { title: 'Pilloni' },
            { title: 'Pasta Club' },
            { title: 'West Side Pizza' },
            { title: 'La Lupa' },
            { title: "Bird's Nest Yakitori" },
            { title: "Chop Chop Chang's" },
            { title: 'Bar Francine' },
            { title: 'Bar Brutus' },
            { title: 'Lychee Lounge' },
            { title: 'Catchment Brewing Co.' },
            { title: 'The Boundary Hotel' },
            { title: 'El Torito' },
            { title: 'Ballistic Beer West End' },
            { title: 'Fish Lane' },
        ]
    },
    { 
        title: 'CAFÉS',
        options: [
            { title: 'Picnic Café' },
            { title: 'Superthing' },
            { title: 'Morning After' },
            { title: 'Lokal + Co' },
            { title: 'NYC Bagel Deli' },
            { title: 'The Gunshop Café' },
            { title: 'Gelato Messina' },
            { title: 'Lune Croissanterie' },

        ]
    },
    { 
        title: 'SHOPPING',
        options: [
            { title: 'ALDI West End' },
            { title: 'Coles West End' },
            { title: 'Woolworths West Village' },
            { title: 'West Village Shopping Precinct' },
            { title: 'Harris Farm Markets' },

        ]
    },
    { 
        title: 'EDUCATION',
        options: [
            { title: 'Brisbane State High School' },
            { title: 'West End State School' },
            { title: 'TAFE Queensland South Bank' },

        ]
    }
];