import React, { useState } from "react";
import { connect } from 'react-redux'
import { toggleDayNight } from ".";
import ButtonResidencesDayNight from './ButtonResidencesDayNight';
const ConnectViewResidencesDayNight = ({ isAnimating, currRotation, toggleDayNight, Day }) => {

    return !isAnimating && (
        <div className="residences--building-container">
            <div className="residences--building-Day-Night-container">    
            
                <>
                    <ButtonResidencesDayNight data={{ title: 'Night', top: '-3px', left: '10px' }} onSelect={() => toggleDayNight('Night')} selected={Day === 'Night'} /> 
                    <ButtonResidencesDayNight data={{ title: 'Day', top: '-3px', left: '-35px' }} onSelect={() => toggleDayNight('Day')} selected={Day === 'Day'} /> 
                </>
                    

            </div>
        </div> )}

const mapStateToProps = (state) => ({
    currRotation: state.residences.currRotation,
    isAnimating: state.residences.isAnimating,
    Day: state.residences.Day,
  })
  
  const mapDispatchToProps = (dispatch) => ({
    toggleDayNight: (Day) => dispatch(toggleDayNight(Day)),
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(ConnectViewResidencesDayNight)