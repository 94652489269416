import React, { useEffect, useState } from "react";

const MenuDuskSunset = ({ duskSunsetSelected, setDuskSunsetSelected, streetSelected }) => {
    useEffect(() => {
        setDuskSunsetSelected('sunset');
    }, [streetSelected]);

    return (
        <div className={ streetSelected === 2 ? "menu-dusk-sunset-single" : "menu-dusk-sunset" }>
            { streetSelected !== 2 ? (
                <>
                    <span className={ duskSunsetSelected === 'sunset' ? "menu-item-dusk-sunset-selected" : "menu-item-dusk-sunset" } onClick={() => setDuskSunsetSelected('sunset')}>Sunset</span>
                    <span className={ duskSunsetSelected === 'dusk' ? "menu-item-dusk-sunset-selected" : "menu-item-dusk-sunset" } onClick={() => setDuskSunsetSelected('dusk')}>Dusk</span>

                </>
            ) : (
                <span className={ duskSunsetSelected === 'sunset' ? "menu-item-dusk-sunset-selected" : "menu-item-dusk-sunset" } onClick={() => setDuskSunsetSelected('sunset')}>Sunset</span>
            )}
        </div>
    );
}

export default MenuDuskSunset;