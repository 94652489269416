import React from 'react';
import GalleryView from './GalleryView';

export default function ViewLifestyle ({ data }) {
    
    return (
        <>
            <img className='shared--img-fill' src={process.env.PUBLIC_URL + '/img/lifestyle/bkg--lifestyle-1.jpg'} width='1920' height='1080' alt='' style={{ position: 'absolute', left:0, top: 100  }}/>
            <GalleryView data={data} />
        </>
    );
}